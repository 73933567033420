<template>
  <div class="pay">
    <h3>付款完成后报名生效</h3>
    <div class="PaymentBox">
      <div class="PaymentDetail">
        <table border="1">
          <tr>
            <td>报名人</td>
            <td>{{ payList.applyName }}</td>
          </tr>
          <tr>
            <td>配送村镇</td>
            <td>{{ payList.address }}</td>
          </tr>
        </table>
        <table border="1">
          <tr>
            <td>产品信息</td>
            <td>最高限价</td>
            <td>数量</td>
            <td>最高总价</td>
            <td>预付款10%(元)</td>
          </tr>
          <tr
            v-for="(item, index) in payList.newAgriculturalVoList"
            :key="index"
          >
            <td>{{ item.agriculturalType }}</td>
            <td>{{ item.guidePrice }}</td>
            <td>{{ item.quantity }}袋</td>
            <td>{{ item.price }}</td>
            <td>{{ item.paymentRatio*100 }}%</td>
          </tr>
        </table>
        <table border="1">
          <tr>
            <td>总价：</td>
            <td>{{ payList.totalPrice }}(一万两千五百元整)</td>
          </tr>
          <tr>
            <td>预付费：</td>
            <td>{{ payList.prepayment }}(一千二百五十元)</td>
          </tr>
        </table>
      </div>
      <div class="dimensionalCode">
        <p>收款账户:{{ payList.account }}</p>
        <p>账户名称:{{ payList.khm }}</p>
        <p>开户行:{{ payList.khh }}</p>
        <div class="img">
          <img src="../../assets/banner-1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="boxTitle">
      <el-button type="primary">付款完成</el-button>
    </div>
  </div>
</template>

<script>
import { selectPaymentDetail } from "@/api/CollectivePurchase";
export default {
  name: "NowPayment",

  data() {
    return {
      applyId: "",
      payList: [],
    };
  },
  created() {
    this.applyId = this.$route.query.data;
    this.payPage();
  },
  // mounted() {},
  methods: {
    async payPage() {
      let params = { applyId: this.applyId };
      let res = await selectPaymentDetail(params);
      this.payList = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.pay {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  text-align: left;
  padding-bottom: 128px;
  h3 {
    padding: 10px 10px;
  }
  .PaymentBox {
    width: 99%;
    height: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .PaymentDetail {
      border-top: solid 2px #1ea84d;
      width: 65%;
      table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 36px;
        tr td {
          text-align: center;
        }
      }
    }
    .dimensionalCode {
      width: 30%;
      p {
        font-weight: bold;
        font-size: 17px;
      }
      img {
        width: 80%;
        height: 100%;
      }
    }
  }
  .boxTitle {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    button {
      width: 20%;
    }
  }
}
</style>
